<template>
  <div class="container">
    <img src="@/assets/images/socialResponsibility1.jpg" />
    <video class="video" src="https://cdn.yscase.com/2023/1204/operation-smile.m4v" controls poster="@/assets/images/socialResponsibility3.jpg"></video>
    <img src="@/assets/images/socialResponsibility2.jpg" />
  </div>
</template>

<script>
export default {
  
}
</script>

<style lang="scss" scoped>
.container {
  img {
    display: block;
    width: 100%;
  }
  .video {
    width: 100%;
    height: 270px;
  }
}
</style>